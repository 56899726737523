import React from 'react'
import About from '../components/Pages/About/About'
import Layout from '../hoc/Layout/Layout'

export default () => {
  return (
    <Layout>
      <About />
    </Layout>
  )
}
