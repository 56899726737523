import React from 'react'
import classes from './About.module.css'
import SEO from '../../seo'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

export default () => {
  const query = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "about/project-manager-about.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <div className={classes.PageContainer}>
      <SEO
        title="PMP Exam Simulator | About Us | PMP Training"
        keywords={[`PMP`, `PMP Exam`, `Project Management`]}
        description="Sample Questions to get PMP Certification. PMP Exam Simulator to Sharpen Your Skills."
      ></SEO>
      <div className={classes.Page}>
        <div className={classes.Photo}>
          <Img
            fluid={query.file.childImageSharp.fluid}
            alt="Project Manager Yury Shkoda"
            className={classes.Image}
          />
        </div>
        <div className={classes.Text_0}>
          <h1>
            Hey, I'm{' '}
            <a
              href="https://tqcoders.com/web-development-about"
              rel="noopener noreferrer"
              target="_blank"
              className={classes.Link}
            >
              Yury Shkoda
            </a>
          </h1>
        </div>
        <div className={classes.Text_1}>
          <p>
            I am a Project Management Professional from New York. I've always
            been working on different projects and at some point, I realized
            that I had to get a PMP certificate. I passed my PMP exam in 2019
            and it gave a great boost to my career and resulted in my current
            position as Chief Executive Officer at{' '}
            <a
              href="https://tqcoders.com"
              rel="noopener noreferrer"
              target="_blank"
              className={classes.Link}
              style={{ fontWeight: '550' }}
            >
              Web Development Company&nbsp;tqCoders
            </a>
            .
          </p>
        </div>
        <div className={classes.Text_2}>
          <p>
            Are you striving to get PMP certification and be a part of the
            largest PM community? I've been there… Are you trying to choose from
            tons of books, courses and training materials to prepare for your
            Project Management Professional certification? I've been there as
            well…
          </p>
        </div>
        <div className={classes.Text_3}>
          <p>
            Back then, when I did not have my PMP Certification yet, I couldn't
            find one platform to practice answering questions and test myself by
            using PMP Exam Simulator. Now you have this opportunity. You can
            find more than thousand questions with in-depth explanations to
            sharpen your skills. And all you have to invest is your time. The
            more you practice, the easier it becomes for you to get through the
            actual exam with flying colors. PMP Exam Simulator is a very useful
            predictor of your score and can help you decide how ready you are
            for the actual exam. After finishing Mock Exam you will get detailed
            statistics and performance chart that will give you clear
            understanding whether you are ready or you have to improve some
            knowledge areas. Besides, there are features that allow to go
            through the mistakes to make sure you don't make them again.
          </p>
        </div>
        <div className={classes.Text_4}>
          <p>
            I am sure that this website will be of great benefit to PMP
            candidates. Apart from other must-read materials, practicing
            multiple questions is one more step towards success. Take advantage
            of this great tool to nail your exam.
          </p>
        </div>
        <div className={classes.Text_5}>
          <p>
            Now, jump into it and
            <br />
            The best of luck!
          </p>
        </div>
      </div>
    </div>
  )
}
